// @mui
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
// ----------------------------------------------------------------------

export default function VisasSkeleton({ ...other }) {
  const _bookingsOverview = [...Array(4)].map((_, index) => ({
    status: ['pending', 'success', 'failed', 'waiting'][index],
  }));

  return (
    <Card {...other}>
      <CardHeader title="Visas Status" />

      <Stack spacing={3} sx={{ p: 3 }}>
        {_bookingsOverview.map((progress) => (
          <Stack key={progress.status}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Box sx={{ typography: 'overline' }}>{progress.status}</Box>
              <Box sx={{ typography: 'subtitle1' }}>
                <CircularProgress size={12} color="primary" />
              </Box>
            </Stack>

            <LinearProgress
              color={
                (progress.status === 'pending' && 'warning') ||
                (progress.status === 'success' && 'success') ||
                (progress.status === 'waiting' && 'info') ||
                (progress.status === 'failed' && 'error') ||
                'default'
              }
              sx={{ height: 8 }}
            />
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
