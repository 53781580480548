/* eslint-disable react-hooks/rules-of-hooks */
import { format, getTime, formatDistanceToNow } from 'date-fns';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formattedDate(date) {
  const { currentLang } = useLocales();
  const locale = currentLang.value;

  return date ? new Date(date).toLocaleDateString(locale) : null;
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
