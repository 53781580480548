export const countries = [
  { code: '', label: '', phone: '', id: '', labelfa: '', slug: '' },
  { code: 'AD', label: 'Andorra', phone: '376', id: '1', labelfa: 'آندورا', slug: 'andorra' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    id: '2',
    labelfa: 'امارات متحده عربی',
    slug: 'united-arab-emirates',
  },
  {
    code: 'AF',
    label: 'Afghanistan',
    phone: '93',
    id: '3',
    labelfa: 'افغانستان',
    slug: 'afghanistan',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
    id: '4',
    labelfa: 'آنتیگوا و باربودا',
    slug: 'antigua-and-barbuda',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264', id: '5', labelfa: 'آنگویلا', slug: 'anguilla' },
  { code: 'AL', label: 'Albania', phone: '355', id: '6', labelfa: 'آلبانی', slug: 'albania' },
  { code: 'AM', label: 'Armenia', phone: '374', id: '7', labelfa: 'ارمنستان', slug: 'armenia' },
  { code: 'AO', label: 'Angola', phone: '244', id: '8', labelfa: 'آنگولا', slug: 'angola' },
  {
    code: 'AQ',
    label: 'Antarctica',
    phone: '672',
    id: '9',
    labelfa: 'جنوبگان',
    slug: 'antarctica',
  },
  { code: 'AR', label: 'Argentina', phone: '54', id: '10', labelfa: 'آرژانتین', slug: 'argentina' },
  {
    code: 'AS',
    label: 'American Samoa',
    phone: '1-684',
    id: '11',
    labelfa: 'ساموای امریکایی',
    slug: 'american-samoa',
  },
  { code: 'AT', label: 'Austria', phone: '43', id: '12', labelfa: 'اتریش', slug: 'austria' },
  { code: 'AU', label: 'Australia', phone: '61', id: '13', labelfa: 'استرالیا', slug: 'australia' },
  { code: 'AW', label: 'Aruba', phone: '297', id: '14', labelfa: 'آروبا', slug: 'aruba' },
  {
    code: 'AX',
    label: 'Alland Islands',
    phone: '358',
    id: '15',
    labelfa: 'جزایر الند',
    slug: 'alland-islands',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    phone: '994',
    id: '16',
    labelfa: 'آذربایجان',
    slug: 'azerbaijan',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    id: '17',
    labelfa: 'بوسنی و هرزگوین',
    slug: 'bosnia-and-herzegovina',
  },
  {
    code: 'BB',
    label: 'Barbados',
    phone: '1-246',
    id: '18',
    labelfa: 'باربادوس',
    slug: 'barbados',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
    id: '19',
    labelfa: 'بنگلادش',
    slug: 'bangladesh',
  },
  { code: 'BE', label: 'Belgium', phone: '32', id: '20', labelfa: 'بلژیک', slug: 'belgium' },
  {
    code: 'BF',
    label: 'Burkina Faso',
    phone: '226',
    id: '21',
    labelfa: 'بورکینافاسو',
    slug: 'burkina-faso',
  },
  { code: 'BG', label: 'Bulgaria', phone: '359', id: '22', labelfa: 'بلغارستان', slug: 'bulgaria' },
  { code: 'BH', label: 'Bahrain', phone: '973', id: '23', labelfa: 'بحرین', slug: 'bahrain' },
  { code: 'BI', label: 'Burundi', phone: '257', id: '24', labelfa: 'بوروندی', slug: 'burundi' },
  { code: 'BJ', label: 'Benin', phone: '229', id: '25', labelfa: 'بنین', slug: 'benin' },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
    id: '26',
    labelfa: 'سنت بارتلمی',
    slug: 'saint-barthelemy',
  },
  { code: 'BM', label: 'Bermuda', phone: '1-441', id: '27', labelfa: 'برمودا', slug: 'bermuda' },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    phone: '673',
    id: '28',
    labelfa: 'برونئی',
    slug: 'brunei-darussalam',
  },
  { code: 'BO', label: 'Bolivia', phone: '591', id: '29', labelfa: 'بولیوی', slug: 'bolivia' },

  { code: 'BR', label: 'Brazil', phone: '55', id: '30', labelfa: 'برزیل', slug: 'brazil' },
  { code: 'BS', label: 'Bahamas', phone: '1-242', id: '31', labelfa: 'باهاما', slug: 'bahamas' },
  { code: 'BT', label: 'Bhutan', phone: '975', id: '32', labelfa: 'بوتان', slug: 'bhutan' },
  {
    code: 'BV',
    label: 'Bouvet Island',
    phone: '47',
    id: '33',
    labelfa: 'جزیره بووه',
    slug: 'bouvet-island',
  },
  { code: 'BW', label: 'Botswana', phone: '267', id: '34', labelfa: 'بوتسوانا', slug: 'botswana' },
  { code: 'BY', label: 'Belarus', phone: '375', id: '35', labelfa: 'بلاروس', slug: 'belarus' },
  { code: 'BZ', label: 'Belize', phone: '501', id: '36', labelfa: 'بلیز', slug: 'belize' },
  { code: 'CA', label: 'Canada', phone: '1', id: '37', labelfa: 'کانادا', slug: 'canada' },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
    id: '38',
    labelfa: 'جزایر کوکوس',
    slug: 'cocos-keeling-islands',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
    id: '39',
    labelfa: 'کنگو، جمهوری دموکراتیک',
    slug: 'congo-democratic-republic',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
    id: '40',
    labelfa: 'جمهوری آفریقای مرکزی',
    slug: 'central-african-republic',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
    id: '41',
    labelfa: 'جمهوری کنگو',
    slug: 'congo-republic',
  },
  {
    code: 'CH',
    label: 'Switzerland',
    phone: '41',
    id: '42',
    labelfa: 'سوئیس',
    slug: 'switzerland',
  },
  {
    code: 'CI',
    label: "Cote d'Ivoire",
    phone: '225',
    id: '43',
    labelfa: 'ساحل عاج',
    slug: 'cote-d-ivoire',
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    phone: '682',
    id: '44',
    labelfa: 'جزایر کوک',
    slug: 'cook-islands',
  },
  { code: 'CL', label: 'Chile', phone: '56', id: '45', labelfa: 'شیلی', slug: 'chile' },
  { code: 'CM', label: 'Cameroon', phone: '237', id: '46', labelfa: 'کامرون', slug: 'cameroon' },
  { code: 'CN', label: 'China', phone: '86', id: '47', labelfa: 'چین', slug: 'china' },
  { code: 'CO', label: 'Colombia', phone: '57', id: '48', labelfa: 'کلمبیا', slug: 'colombia' },
  {
    code: 'CR',
    label: 'Costa Rica',
    phone: '506',
    id: '49',
    labelfa: 'کاستاریکا',
    slug: 'costa-rica',
  },
  { code: 'CU', label: 'Cuba', phone: '53', id: '50', labelfa: 'کوبا', slug: 'cuba' },
  {
    code: 'CV',
    label: 'Cape Verde',
    phone: '238',
    id: '51',
    labelfa: 'کیپ ورد',
    slug: 'cape-verde',
  },
  { code: 'CW', label: 'Curacao', phone: '599', id: '52', labelfa: 'کوراسائو', slug: 'curacao' },
  {
    code: 'CX',
    label: 'Christmas Island',
    phone: '61',
    id: '53',
    labelfa: 'جزیره کریسمس',
    slug: 'christmas-island',
  },
  { code: 'CY', label: 'Cyprus', phone: '357', id: '54', labelfa: 'قبرس', slug: 'cyprus' },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
    id: '55',
    labelfa: 'جمهوری چک',
    slug: 'czech-republic',
  },
  { code: 'DE', label: 'Germany', phone: '49', id: '56', labelfa: 'آلمان', slug: 'germany' },
  { code: 'DJ', label: 'Djibouti', phone: '253', id: '57', labelfa: 'جیبوتی', slug: 'djibouti' },
  { code: 'DK', label: 'Denmark', phone: '45', id: '58', labelfa: 'دانمارک', slug: 'denmark' },
  {
    code: 'DM',
    label: 'Dominica',
    phone: '1-767',
    id: '59',
    labelfa: 'دومینیکا',
    slug: 'dominica',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    id: '60',
    labelfa: 'جمهوری دومینیکن',
    slug: 'dominican-republic',
  },
  { code: 'DZ', label: 'Algeria', phone: '213', id: '61', labelfa: 'الجزایر', slug: 'algeria' },
  { code: 'EC', label: 'Ecuador', phone: '593', id: '62', labelfa: 'اکوادور', slug: 'ecuador' },
  { code: 'EE', label: 'Estonia', phone: '372', id: '63', labelfa: 'استونی', slug: 'estonia' },
  { code: 'EG', label: 'Egypt', phone: '20', id: '64', labelfa: 'مصر', slug: 'egypt' },
  {
    code: 'EH',
    label: 'Western Sahara',
    phone: '212',
    id: '65',
    labelfa: 'صحرای غربی',
    slug: 'western-sahara',
  },
  { code: 'ER', label: 'Eritrea', phone: '291', id: '66', labelfa: 'اریتره', slug: 'eritrea' },
  { code: 'ES', label: 'Spain', phone: '34', id: '67', labelfa: 'اسپانیا', slug: 'spain' },
  { code: 'ET', label: 'Ethiopia', phone: '251', id: '68', labelfa: 'اتیوپی', slug: 'ethiopia' },
  { code: 'FI', label: 'Finland', phone: '358', id: '69', labelfa: 'فنلاند', slug: 'finland' },
  { code: 'FJ', label: 'Fiji', phone: '679', id: '70', labelfa: 'فیجی', slug: 'fiji' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
    id: '71',
    labelfa: 'جزایر فالکلند',
    slug: 'falkland-islands-malvinas-',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
    id: '72',
    labelfa: 'میکرونزی، ایالات فدرال',
    slug: 'micronesia-federated-states-of',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    phone: '298',
    id: '73',
    labelfa: 'جزایر فارو',
    slug: 'faroe-islands',
  },
  { code: 'FR', label: 'France', phone: '33', id: '74', labelfa: 'فرانسه', slug: 'france' },
  { code: 'GA', label: 'Gabon', phone: '241', id: '75', labelfa: 'گابن', slug: 'gabon' },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
    id: '76',
    labelfa: 'بریتانیا',
    slug: 'united-kingdom',
  },
  { code: 'GD', label: 'Grenada', phone: '1-473', id: '77', labelfa: 'گرنادا', slug: 'grenada' },
  { code: 'GE', label: 'Georgia', phone: '995', id: '78', labelfa: 'گرجستان', slug: 'georgia' },
  {
    code: 'GF',
    label: 'French Guiana',
    phone: '594',
    id: '79',
    labelfa: 'گویان فرانسه',
    slug: 'french-guiana',
  },
  { code: 'GG', label: 'Guernsey', phone: '44', id: '80', labelfa: 'گرنزی', slug: 'guernsey' },
  { code: 'GH', label: 'Ghana', phone: '233', id: '81', labelfa: 'غنا', slug: 'ghana' },
  {
    code: 'GI',
    label: 'Gibraltar',
    phone: '350',
    id: '82',
    labelfa: 'جبل‌الطارق',
    slug: 'gibraltar',
  },
  { code: 'GL', label: 'Greenland', phone: '299', id: '83', labelfa: 'گرینلند', slug: 'greenland' },
  { code: 'GM', label: 'Gambia', phone: '220', id: '84', labelfa: 'گامبیا', slug: 'gambia' },
  { code: 'GN', label: 'Guinea', phone: '224', id: '85', labelfa: 'گینه', slug: 'guinea' },
  {
    code: 'GP',
    label: 'Guadeloupe',
    phone: '590',
    id: '86',
    labelfa: 'جزیره گوادلوپ',
    slug: 'guadeloupe',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    phone: '240',
    id: '87',
    labelfa: 'گینه استوایی',
    slug: 'equatorial-guinea',
  },
  { code: 'GR', label: 'Greece', phone: '30', id: '88', labelfa: 'یونان', slug: 'greece' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
    id: '89',
    labelfa: 'جورجیای جنوبی و جزایر ساندویچ جنوبی',
    slug: 'south-georgia-and-the-south-sandwich-islands',
  },
  {
    code: 'GT',
    label: 'Guatemala',
    phone: '502',
    id: '90',
    labelfa: 'گواتمالا',
    slug: 'guatemala',
  },
  { code: 'GU', label: 'Guam', phone: '1-671', id: '91', labelfa: 'گوام', slug: 'guam' },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
    id: '92',
    labelfa: 'گینه بیسائو',
    slug: 'guinea-bissau',
  },
  { code: 'GY', label: 'Guyana', phone: '592', id: '93', labelfa: 'گویان', slug: 'guyana' },
  { code: 'HK', label: 'Hong Kong', phone: '852', id: '94', labelfa: 'هنگ‌کنگ', slug: 'hong-kong' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
    id: '95',
    labelfa: 'جزایر هرد و مک‌دونالد',
    slug: 'heard-island-and-mcdonald-islands',
  },
  { code: 'HN', label: 'Honduras', phone: '504', id: '96', labelfa: 'هندوراس', slug: 'honduras' },
  { code: 'HR', label: 'Croatia', phone: '385', id: '97', labelfa: 'کرواسی', slug: 'croatia' },
  { code: 'HT', label: 'Haiti', phone: '509', id: '98', labelfa: 'هائیتی', slug: 'haiti' },
  { code: 'HU', label: 'Hungary', phone: '36', id: '99', labelfa: 'مجارستان', slug: 'hungary' },
  { code: 'ID', label: 'Indonesia', phone: '62', id: '100', labelfa: 'اندونزی', slug: 'indonesia' },
  { code: 'IE', label: 'Ireland', phone: '353', id: '101', labelfa: 'ایرلند', slug: 'ireland' },
  { code: 'IL', label: 'Israel', phone: '972', id: '102', labelfa: 'اسرائیل', slug: 'israel' },
  {
    code: 'IM',
    label: 'Isle of Man',
    phone: '44',
    id: '103',
    labelfa: 'جزیره من',
    slug: 'isle-of-man',
  },
  { code: 'IN', label: 'India', phone: '91', id: '104', labelfa: 'هند', slug: 'india' },

  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
    id: '105',
    labelfa: 'قلمرو اقیانوس هند بریتانیا',
    slug: 'british-indian-ocean-territory',
  },
  {
    code: 'IQ',
    label: 'Iraq',
    phone: '964',
    id: '106',
    labelfa: 'عراق',
    slug: 'iraq',
  },
  {
    code: 'IR',
    label: 'Iran',
    phone: '98',
    id: '107',
    labelfa: 'ایران',
    slug: 'iran',
  },
  {
    code: 'IS',
    label: 'Iceland',
    phone: '354',
    id: '108',
    labelfa: 'ایسلند',
    slug: 'iceland',
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    id: '109',
    labelfa: 'ایتالیا',
    slug: 'italy',
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
    id: '110',
    labelfa: 'جرزی',
    slug: 'jersey',
  },
  {
    code: 'JM',
    label: 'Jamaica',
    phone: '1-876',
    id: '111',
    labelfa: 'جامائیکا',
    slug: 'jamaica',
  },
  {
    code: 'JO',
    label: 'Jordan',
    phone: '962',
    id: '112',
    labelfa: 'اردن',
    slug: 'jordan',
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
    id: '113',
    labelfa: 'ژاپن',
    slug: 'japan',
  },
  {
    code: 'KE',
    label: 'Kenya',
    phone: '254',
    id: '114',
    labelfa: 'کنیا',
    slug: 'kenya',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
    id: '115',
    labelfa: 'قرقیزستان',
    slug: 'kyrgyzstan',
  },
  {
    code: 'KH',
    label: 'Cambodia',
    phone: '855',
    id: '116',
    labelfa: 'کامبوج',
    slug: 'cambodia',
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
    id: '117',
    labelfa: 'کیریباتی',
    slug: 'kiribati',
  },
  {
    code: 'KM',
    label: 'Comoros',
    phone: '269',
    id: '118',
    labelfa: 'کومور',
    slug: 'comoros',
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
    id: '119',
    labelfa: 'سنت کیتس و نویس',
    slug: 'saint-kitts-and-nevis',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
    id: '120',
    labelfa: 'کره، جمهوری دموکراتیک خلق کره',
    slug: 'korea-democratic-peoples-republic-of',
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
    phone: '82',
    id: '121',
    labelfa: 'کره، جمهوری کره',
    slug: 'korea-republic-of',
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
    id: '122',
    labelfa: 'کویت',
    slug: 'kuwait',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
    id: '123',
    labelfa: 'جزایر کیمن',
    slug: 'cayman-islands',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
    id: '124',
    labelfa: 'قزاقستان',
    slug: 'kazakhstan',
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
    id: '125',
    labelfa: 'جمهوری دموکراتیک خلق لائوس',
    slug: 'lao-peoples-democratic-republic',
  },
  {
    code: 'LB',
    label: 'Lebanon',
    phone: '961',
    id: '126',
    labelfa: 'لبنان',
    slug: 'lebanon',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
    id: '127',
    labelfa: 'سنت لوسیا',
    slug: 'saint-lucia',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    id: '128',
    labelfa: 'لیختن‌اشتاین',
    slug: 'liechtenstein',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
    id: '129',
    labelfa: 'سری‌لانکا',
    slug: 'sri-lanka',
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
    id: '130',
    labelfa: 'لیبریا',
    slug: 'liberia',
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
    id: '131',
    labelfa: 'لسوتو',
    slug: 'lesotho',
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    id: '132',
    labelfa: 'لیتوانی',
    slug: 'lithuania',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    id: '133',
    labelfa: 'لوکزامبورگ',
    slug: 'luxembourg',
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    id: '134',
    labelfa: 'لتونی',
    slug: 'latvia',
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
    id: '135',
    labelfa: 'لیبی',
    slug: 'libya',
  },
  { code: 'MA', label: 'Morocco', phone: '212', id: '136', labelfa: 'مراکش', slug: 'morocco' },
  { code: 'MC', label: 'Monaco', phone: '377', id: '137', labelfa: 'موناکو', slug: 'monaco' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
    id: '138',
    labelfa: 'مولداوی',
    slug: 'moldova,-republic-of',
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
    id: '139',
    labelfa: 'مونته‌نگرو',
    slug: 'montenegro',
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
    id: '140',
    labelfa: 'سنت مارتین (بخش فرانسوی)',
    slug: 'saint-martin-(french-part)',
  },
  {
    code: 'MG',
    label: 'Madagascar',
    phone: '261',
    id: '141',
    labelfa: 'ماداگاسکار',
    slug: 'madagascar',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    phone: '692',
    id: '142',
    labelfa: 'جزایر مارشال',
    slug: 'marshall-islands',
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    id: '143',
    labelfa: 'مقدونیه، جمهوری سابق یوگسلاو',
    slug: 'macedonia,-the-former-yugoslav-republic-of',
  },
  { code: 'ML', label: 'Mali', phone: '223', id: '144', labelfa: 'مالی', slug: 'mali' },
  { code: 'MM', label: 'Myanmar', phone: '95', id: '145', labelfa: 'میانمار', slug: 'myanmar' },
  { code: 'MN', label: 'Mongolia', phone: '976', id: '146', labelfa: 'مغولستان', slug: 'mongolia' },
  { code: 'MO', label: 'Macao', phone: '853', id: '147', labelfa: 'ماکائو', slug: 'macao' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
    id: '148',
    labelfa: 'جزایر ماریانای شمالی',
    slug: 'northern-mariana-islands',
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
    id: '149',
    labelfa: 'مارتینیک',
    slug: 'martinique',
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
    id: '150',
    labelfa: 'موریتانی',
    slug: 'mauritania',
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
    id: '151',
    labelfa: 'مونتسرات',
    slug: 'montserrat',
  },
  { code: 'MT', label: 'Malta', phone: '356', id: '152', labelfa: 'مالت', slug: 'malta' },
  { code: 'MU', label: 'Mauritius', phone: '230', id: '153', labelfa: 'موریس', slug: 'mauritius' },
  { code: 'MV', label: 'Maldives', phone: '960', id: '154', labelfa: 'مالدیو', slug: 'maldives' },
  { code: 'MW', label: 'Malawi', phone: '265', id: '155', labelfa: 'مالاوی', slug: 'malawi' },
  { code: 'MX', label: 'Mexico', phone: '52', id: '156', labelfa: 'مکزیک', slug: 'mexico' },
  { code: 'MY', label: 'Malaysia', phone: '60', id: '157', labelfa: 'مالزی', slug: 'malaysia' },
  {
    code: 'MZ',
    label: 'Mozambique',
    phone: '258',
    id: '158',
    labelfa: 'موزامبیک',
    slug: 'mozambique',
  },
  { code: 'NA', label: 'Namibia', phone: '264', id: '159', labelfa: 'نامیبیا', slug: 'namibia' },
  {
    code: 'NC',
    label: 'New Caledonia',
    phone: '687',
    id: '160',
    labelfa: 'کالدونیای جدید',
    slug: 'new-caledonia',
  },
  { code: 'NE', label: 'Niger', phone: '227', id: '161', labelfa: 'نیجر', slug: 'niger' },
  {
    code: 'NF',
    label: 'Norfolk Island',
    phone: '672',
    id: '162',
    labelfa: 'جزیره نورفولک',
    slug: 'norfolk-island',
  },
  { code: 'NG', label: 'Nigeria', phone: '234', id: '163', labelfa: 'نیجریه', slug: 'nigeria' },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
    id: '164',
    labelfa: 'نیکاراگوئه',
    slug: 'nicaragua',
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    id: '165',
    labelfa: 'هلند',
    slug: 'netherlands',
  },
  { code: 'NO', label: 'Norway', phone: '47', id: '166', labelfa: 'نروژ', slug: 'norway' },
  { code: 'NP', label: 'Nepal', phone: '977', id: '167', labelfa: 'نپال', slug: 'nepal' },
  { code: 'NR', label: 'Nauru', phone: '674', id: '168', labelfa: 'نائورو', slug: 'nauru' },
  { code: 'NU', label: 'Niue', phone: '683', id: '169', labelfa: 'نیوئه', slug: 'niue' },
  {
    code: 'NZ',
    label: 'New Zealand',
    phone: '64',
    id: '170',
    labelfa: 'نیوزیلند',
    slug: 'new-zealand',
  },
  { code: 'OM', label: 'Oman', phone: '968', id: '171', labelfa: 'عمان', slug: 'oman' },
  { code: 'PA', label: 'Panama', phone: '507', id: '172', labelfa: 'پاناما', slug: 'panama' },
  { code: 'PE', label: 'Peru', phone: '51', id: '173', labelfa: 'پرو', slug: 'peru' },
  {
    code: 'PF',
    label: 'French Polynesia',
    phone: '689',
    id: '174',
    labelfa: 'پلی‌نزی فرانسه',
    slug: 'french-polynesia',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
    id: '175',
    labelfa: 'پاپوآ گینه نو',
    slug: 'papua-new-guinea',
  },
  {
    code: 'PH',
    label: 'Philippines',
    phone: '63',
    id: '176',
    labelfa: 'فیلیپین',
    slug: 'philippines',
  },
  { code: 'PK', label: 'Pakistan', phone: '92', id: '177', labelfa: 'پاکستان', slug: 'pakistan' },
  { code: 'PL', label: 'Poland', phone: '48', id: '178', labelfa: 'لهستان', slug: 'poland' },

  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
    id: '179',
    labelfa: 'سن پیر و میکلن',
    slug: 'saint-pierre-and-miquelon',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870', id: '180', labelfa: 'پیتکرن', slug: 'pitcairn' },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
    id: '181',
    labelfa: 'پورتوریکو',
    slug: 'puerto-rico',
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
    id: '182',
    labelfa: 'فلسطین، دولت',
    slug: 'palestine,-state-of',
  },
  { code: 'PT', label: 'Portugal', phone: '351', id: '183', labelfa: 'پرتغال', slug: 'portugal' },
  { code: 'PW', label: 'Palau', phone: '680', id: '184', labelfa: 'پالائو', slug: 'palau' },
  { code: 'PY', label: 'Paraguay', phone: '595', id: '185', labelfa: 'پاراگوئه', slug: 'paraguay' },
  { code: 'QA', label: 'Qatar', phone: '974', id: '186', labelfa: 'قطر', slug: 'qatar' },
  { code: 'RE', label: 'Reunion', phone: '262', id: '187', labelfa: 'رئونیون', slug: 'reunion' },
  { code: 'RO', label: 'Romania', phone: '40', id: '188', labelfa: 'رومانی', slug: 'romania' },
  { code: 'RS', label: 'Serbia', phone: '381', id: '189', labelfa: 'صربستان', slug: 'serbia' },
  {
    code: 'RU',
    label: 'Russian Federation',
    phone: '7',
    id: '190',
    labelfa: 'فدراسیون روسیه',
    slug: 'russian-federation',
  },
  { code: 'RW', label: 'Rwanda', phone: '250', id: '191', labelfa: 'رواندا', slug: 'rwanda' },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    phone: '966',
    id: '192',
    labelfa: 'عربستان سعودی',
    slug: 'saudi-arabia',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    phone: '677',
    id: '193',
    labelfa: 'جزایر سلیمان',
    slug: 'solomon-islands',
  },
  { code: 'SC', label: 'Seychelles', phone: '248', id: '194', labelfa: 'سیشل', slug: 'seychelles' },
  { code: 'SD', label: 'Sudan', phone: '249', id: '195', labelfa: 'سودان', slug: 'sudan' },
  { code: 'SE', label: 'Sweden', phone: '46', id: '196', labelfa: 'سوئد', slug: 'sweden' },
  { code: 'SG', label: 'Singapore', phone: '65', id: '197', labelfa: 'سنگاپور', slug: 'singapore' },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
    id: '198',
    labelfa: 'سنت هلنا',
    slug: 'saint-helena',
  },
  { code: 'SI', label: 'Slovenia', phone: '386', id: '199', labelfa: 'اسلوونی', slug: 'slovenia' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
    id: '200',
    labelfa: 'اسوالبارد و یان ماین',
    slug: 'svalbard-and-jan-mayen',
  },
  { code: 'SK', label: 'Slovakia', phone: '421', id: '201', labelfa: 'اسلواکی', slug: 'slovakia' },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
    id: '202',
    labelfa: 'سیرالئون',
    slug: 'sierra-leone',
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
    id: '203',
    labelfa: 'سان مارینو',
    slug: 'san-marino',
  },
  { code: 'SN', label: 'Senegal', phone: '221', id: '204', labelfa: 'سنگال', slug: 'senegal' },
  { code: 'SO', label: 'Somalia', phone: '252', id: '205', labelfa: 'سومالی', slug: 'somalia' },
  { code: 'SR', label: 'Suriname', phone: '597', id: '206', labelfa: 'سورینام', slug: 'suriname' },
  {
    code: 'SS',
    label: 'South Sudan',
    phone: '211',
    id: '207',
    labelfa: 'سودان جنوبی',
    slug: 'south-sudan',
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
    id: '208',
    labelfa: 'سائوتومه و پرینسیپ',
    slug: 'sao-tome-and-principe',
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
    id: '209',
    labelfa: 'السالوادور',
    slug: 'el-salvador',
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    id: '210',
    labelfa: 'سینت مارتن (قسمت هلندی)',
    slug: 'sint-maarten-(dutch-part)',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
    id: '211',
    labelfa: 'جمهوری عربی سوریه',
    slug: 'syrian-arab-republic',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    phone: '268',
    id: '212',
    labelfa: 'سوازیلند',
    slug: 'swaziland',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
    id: '213',
    labelfa: 'جزایر تورکس و کایکوس',
    slug: 'turks-and-caicos-islands',
  },
  { code: 'TD', label: 'Chad', phone: '235', id: '214', labelfa: 'چاد', slug: 'chad' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
    id: '215',
    labelfa: 'سرزمین‌های جنوبی فرانسه',
    slug: 'french-southern-territories',
  },
  { code: 'TG', label: 'Togo', phone: '228', id: '216', labelfa: 'توگو', slug: 'togo' },
  { code: 'TH', label: 'Thailand', phone: '66', id: '217', labelfa: 'تایلند', slug: 'thailand' },
  {
    code: 'TJ',
    label: 'Tajikistan',
    phone: '992',
    id: '218',
    labelfa: 'تاجیکستان',
    slug: 'tajikistan',
  },
  { code: 'TK', label: 'Tokelau', phone: '690', id: '219', labelfa: 'توکلائو', slug: 'tokelau' },
  {
    code: 'TL',
    label: 'Timor-Leste',
    phone: '670',
    id: '220',
    labelfa: 'تیمور شرقی',
    slug: 'timor-leste',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
    id: '221',
    labelfa: 'ترکمنستان',
    slug: 'turkmenistan',
  },
  { code: 'TN', label: 'Tunisia', phone: '216', id: '222', labelfa: 'تونس', slug: 'tunisia' },
  { code: 'TO', label: 'Tonga', phone: '676', id: '223', labelfa: 'تونگا', slug: 'tonga' },
  { code: 'TR', label: 'Turkey', phone: '90', id: '224', labelfa: 'ترکیه', slug: 'turkey' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    id: '225',
    labelfa: 'ترینیداد و توباگو',
    slug: 'trinidad-and-tobago',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688', id: '226', labelfa: 'تووالو', slug: 'tuvalu' },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
    id: '227',
    labelfa: 'تایوان، استان چین',
    slug: 'taiwan,-province-of-china',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
    id: '228',
    labelfa: 'جمهوری متحده تانزانیا',
    slug: 'united-republic-of-tanzania',
  },
  { code: 'UA', label: 'Ukraine', phone: '380', id: '229', labelfa: 'اوکراین', slug: 'ukraine' },
  { code: 'UG', label: 'Uganda', phone: '256', id: '230', labelfa: 'اوگاندا', slug: 'uganda' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    id: '231',
    labelfa: 'ایالات متحده',
    slug: 'united-states',
  },
  { code: 'UY', label: 'Uruguay', phone: '598', id: '232', labelfa: 'اروگوئه', slug: 'uruguay' },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
    id: '233',
    labelfa: 'ازبکستان',
    slug: 'uzbekistan',
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
    id: '234',
    labelfa: 'سریر مقدس (واتیکان)',
    slug: 'holy-see-(vatican-city-state)',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    id: '235',
    labelfa: 'سنت وینسنت و گرنادین‌ها',
    slug: 'saint-vincent-and-the-grenadines',
  },
  { code: 'VE', label: 'Venezuela', phone: '58', id: '236', labelfa: 'ونزوئلا', slug: 'venezuela' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
    id: '237',
    labelfa: 'جزایر ویرجین بریتانیا',
    slug: 'british-virgin-islands',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
    id: '238',
    labelfa: 'جزایر ویرجین آمریکا',
    slug: 'us-virgin-islands',
  },
  { code: 'VN', label: 'Vietnam', phone: '84', id: '239', labelfa: 'ویتنام', slug: 'vietnam' },
  { code: 'VU', label: 'Vanuatu', phone: '678', id: '240', labelfa: 'وانواتو', slug: 'vanuatu' },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
    id: '241',
    labelfa: 'والیس و فوتونا',
    slug: 'wallis-and-futuna',
  },
  { code: 'WS', label: 'Samoa', phone: '685', id: '242', labelfa: 'ساموآ', slug: 'samoa' },
  { code: 'XK', label: 'Kosovo', phone: '383', id: '243', labelfa: 'کوزوو', slug: 'kosovo' },
  { code: 'YE', label: 'Yemen', phone: '967', id: '244', labelfa: 'یمن', slug: 'yemen' },
  { code: 'YT', label: 'Mayotte', phone: '262', id: '245', labelfa: 'مایوت', slug: 'mayotte' },
  {
    code: 'ZA',
    label: 'South Africa',
    phone: '27',
    id: '246',
    labelfa: 'آفریقای جنوبی',
    slug: 'south-africa',
  },
  { code: 'ZM', label: 'Zambia', phone: '260', id: '247', labelfa: 'زامبیا', slug: 'zambia' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263', id: '248', labelfa: 'زیمبابوه', slug: 'zimbabwe' },
];
