import { createClient } from '@supabase/supabase-js'

export const supabase = createClient('https://xwgcufmpakhvylxakexx.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh3Z2N1Zm1wYWtodnlseGFrZXh4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQ4NTgwMTEsImV4cCI6MjAwMDQzNDAxMX0.5p_JVXQVaeKCGR6rVexPpBxkrbFd6wdo4IKO2bTbEz8')

export const supabaseAdmin = createClient('https://xwgcufmpakhvylxakexx.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh3Z2N1Zm1wYWtodnlseGFrZXh4Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4NDg1ODAxMSwiZXhwIjoyMDAwNDM0MDExfQ.NHOmfeXAwnhzuDQbsUHAW1PeYOjWPxWJxbyPbbW1mwQ', {
    auth: {
      autoRefreshToken: false,
      persistSession: false
    }
  })
  