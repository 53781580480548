import PropTypes from 'prop-types';
import { useState, useEffect, useReducer, useCallback, useMemo } from 'react';

import { AuthContext } from './auth-context';
import { supabase, supabaseAdmin } from '../../../lib/supabaseClient'

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      supabase.auth.onAuthStateChange(async (event, session) => {
        const user = session?.user;
        if (user) {
          const profile = await supabase.from('profiles').select(`*`).eq('id', user.id).single();
          // console.log(profile)
          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...profile.data,
                id: profile.data.id,
                displayName: `${profile?.data.first_name_en} ${profile?.data.last_name_en}`,
                photoURL: profile?.data.avatar_url,
                email: user?.email,
                phoneNumber: profile?.data.phonenumber,
                // role: 'admin',
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      // dispatch({
      //   type: 'INITIAL',
      //   payload: {
      //     user: {
      //       ...data,
      //       id: data.id,
      //       displayName: `${data?.first_name}`,
      //       role: 'admin',
      //     },
      //   },
      // });
    } catch (error) {
      // return(error.error_description || error.message);
      throw Error(error.error_description || error.message);
    } finally {
      // console.log("hello")
    }
    // dispatch({
    //   type: 'INITIAL',
    //   payload: {
    //     user: {
    //       ...data,
    //       id: data.id,
    //       displayName: `${data.name}`,
    //       role: 'admin',
    //     },
    //   },
    // });
  }, []);

  // // REGISTER
  // const register = useCallback(async (email, password, firstName, lastName) => {
  //   await Auth.signUp({
  //     username: email,
  //     password,
  //     attributes: {
  //       email,
  //       given_name: firstName,
  //       family_name: lastName,
  //     },
  //   });
  // }, []);

  // // CONFIRM REGISTER
  // const confirmRegister = useCallback(async (email, code) => {
  //   await Auth.confirmSignUp(email, code);
  // }, []);

  // // RESEND CODE REGISTER
  // const resendCodeRegister = useCallback(async (email) => {
  //   await Auth.resendSignUp(email);
  // }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await supabase.auth.signOut();

    // dispatch({
    //   type: 'LOGOUT',
    // });
  }, []);

  // // FORGOT PASSWORD
  // const forgotPassword = useCallback(async (email) => {
  //   await Auth.forgotPassword(email);
  // }, []);

  // // NEW PASSWORD
  // const newPassword = useCallback(async (email, code, password) => {
  //   await Auth.forgotPasswordSubmit(email, code, password);
  // }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'supabase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
